import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Router from "./router/Router";
import { useSelector, useDispatch } from "react-redux";
import { loadUser } from "./store/slices/userSlice";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("UserToken"));
    console.log(storedUser);

    if (storedUser) {
      dispatch(loadUser(storedUser));
    }
  }, [dispatch]);
  return <Router />;
}

export default App;
