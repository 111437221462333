// import React, { useEffect, useState } from "react";
// import {
//   fetchgetNotification,
//   updateNotification,
// } from "../../services/APIService"; // Import the API services
// import Loader from "../../services/Loader"; // Import the Loader

// const Notification = () => {
//   const [notifications, setNotifications] = useState([]);
//   console.log("notifications", notifications);
//   const [loading, setLoading] = useState(true);

//   // Fetch notifications from the API
//   const fetchNotifications = async () => {
//     try {
//       const data = await fetchgetNotification(); // Fetch notifications from APIService
//       // Check if data.notifications exists and is an array
//       console.log("data.notifications", data);
//       setNotifications(data); // Set notifications if valid

//       if (data && Array.isArray(data)) {
//         setNotifications(data); // Set notifications if valid
//       } else {
//         console.warn(
//           "fetchgetNotification: 'notifications' is undefined or not an array",
//           data
//         );
//         setNotifications([]); // Set to empty array if undefined
//       }
//       setLoading(false); // Stop loading when data is fetched
//     } catch (error) {
//       console.error("Error fetching notifications:", error);
//       setNotifications([]); // Optionally set to empty array in case of error
//       setLoading(false); // Stop loading in case of error
//     }
//   };

//   useEffect(() => {
//     fetchNotifications();
//   }, []);

//   // Mark a notification as read and update the isRead status
//   const handleMarkAsRead = async (
//     notificationId,
//     notice,
//     entityType,
//     userId,
//     entityId
//   ) => {
//     console.log("notificationId", notificationId);
//     try {
//       // Ensure payload matches expected backend model structure
//       const payload = {
//         id: notificationId,
//         isRead: 1,
//         userId: userId,
//         entityId: entityId,
//         entityType: entityType,
//         notice: notice,
//       };
//       await updateNotification(payload); // API call to mark notification as read

//       // Update local state to change the isRead status
//       setNotifications((prevNotifications) =>
//         prevNotifications.map((notification) =>
//           notification.id === notificationId
//             ? { ...notification, isRead: 1 } // Update isRead status to 1
//             : notification
//         )
//       );
//     } catch (error) {
//       console.error("Error marking notification as read:", error);
//     }
//   };

//   return (
//     <div className="dashboard-influence">
//       {loading && <Loader />}
//       <div className="container-fluid dashboard-content">
//         <div className="row">
//           <div className="col-xl-12">
//             <h3 className="mb-2">Notifications</h3>
//           </div>
//         </div>

//         <div className="row">
//           <div className="col-xl-12">
//             <div className="card">
//               <div className="card-body">
//                 {/* Check if notifications is a valid array before mapping */}
//                 {Array.isArray(notifications) && notifications.length > 0
//                   ? notifications.map((notification, index) => {
//                       const {
//                         userId,
//                         isRead,
//                         id,
//                         notice,
//                         entityType,
//                         entityId,
//                       } = notification;

//                       return (
//                         <div
//                           key={index}
//                           className="row"
//                           style={{ marginBottom: "20px" }}
//                         >
//                           <div className="col-xl-12">
//                             <div className="card">
//                               <div className="card-body">
//                                 <p>
//                                   <strong>Message:</strong>{" "}
//                                   {notification?.notice} <br />
//                                   <strong>User ID:</strong> {userId}
//                                 </p>
//                                 <button
//                                   style={{
//                                     padding: "10px 20px",
//                                     border: "none",
//                                     color: "white",
//                                     backgroundColor: isRead
//                                       ? "#6c757d"
//                                       : "#28a745",
//                                     cursor: "pointer",
//                                   }}
//                                   onClick={() =>
//                                     handleMarkAsRead(
//                                       id,
//                                       notice,
//                                       entityType,
//                                       userId,
//                                       entityId
//                                     )
//                                   }
//                                   disabled={isRead === 1} // Disable button if already read
//                                 >
//                                   {isRead === 1
//                                     ? "Notification Read"
//                                     : "Mark as Read"}
//                                 </button>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       );
//                     })
//                   : !loading && <p>No notifications available.</p>}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Notification;

import React, { useEffect, useState } from "react";
import {
  fetchgetNotification,
  updateNotification,
} from "../../services/APIService"; // Import the API services
import Loader from "../../services/Loader"; // Import the Loader

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch notifications from the API
  const fetchNotifications = async () => {
    try {
      const data = await fetchgetNotification(); // Fetch notifications from APIService
      if (data && Array.isArray(data)) {
        // Sort notifications by updatedAt in descending order
        const sortedNotifications = data.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setNotifications(sortedNotifications); // Set sorted notifications
      } else {
        console.warn(
          "fetchgetNotification: 'notifications' is undefined or not an array",
          data
        );
        setNotifications([]); // Set to empty array if undefined
      }
      setLoading(false); // Stop loading when data is fetched
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setNotifications([]); // Optionally set to empty array in case of error
      setLoading(false); // Stop loading in case of error
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  // Mark a notification as read and update the isRead status
  const handleMarkAsRead = async (
    notificationId,
    notice,
    entityType,
    userId,
    entityId
  ) => {
    try {
      const payload = {
        id: notificationId,
        isRead: 1,
        userId: userId,
        entityId: entityId,
        entityType: entityType,
        notice: notice,
      };
      await updateNotification(payload); // API call to mark notification as read

      // Update local state to change the isRead status
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, isRead: 1 } // Update isRead status to 1
            : notification
        )
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  return (
    <div className="dashboard-influence">
      {loading && <Loader />}
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12">
            <h3 className="mb-2">Notifications</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                {Array.isArray(notifications) && notifications.length > 0
                  ? notifications.map((notification, index) => {
                      const {
                        userId,
                        isRead,
                        id,
                        notice,
                        entityType,
                        entityId,
                      } = notification;

                      return (
                        <div
                          key={index}
                          className="row"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="col-xl-12">
                            <div className="card">
                              <div className="card-body">
                                <p>
                                  <strong>Message:</strong>{" "}
                                  {notification?.notice} <br />
                                  <strong>User ID:</strong> {userId}
                                </p>
                                <button
                                  style={{
                                    padding: "10px 20px",
                                    border: "none",
                                    color: "white",
                                    backgroundColor: isRead
                                      ? "#6c757d"
                                      : "#28a745",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleMarkAsRead(
                                      id,
                                      notice,
                                      entityType,
                                      userId,
                                      entityId
                                    )
                                  }
                                  disabled={isRead === 1} // Disable button if already read
                                >
                                  {isRead === 1
                                    ? "Notification Read"
                                    : "Mark as Read"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : !loading && <p>No notifications available.</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
