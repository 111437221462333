// import React, { useState, useEffect } from 'react';
// import { useForm } from 'react-hook-form';
// import Loader from "../../services/Loader"; // Assuming you have a Loader component
// import { addProduct, getAllCategory } from '../../services/APIService'; // APIService for addProduct and getAllCategory

// const AddProduct = () => {
//   const { register, handleSubmit, formState: { errors }, setValue, trigger } = useForm();
//   const [loading, setLoading] = useState(false);
//   const [notification, setNotification] = useState({ message: '', type: '' });
//   const [imageError, setImageError] = useState('');
//   const [image, setImage] = useState(null);
//   const [categories, setCategories] = useState([]); // State to store categories
//   const [selectedCategoryId, setSelectedCategoryId] = useState(''); // State to store selected category ID

//   // Fetch categories when component mounts
//   useEffect(() => {
//     const fetchCategories = async () => {

//       try {
//         const response = await getAllCategory();  // Fetch categories from the API
//         if (response) {
//           setCategories(response);  // Store categories in state
//         }
//       } catch (error) {
//         console.error("Error fetching categories", error);
//         setNotification({ message: 'Failed to fetch categories', type: 'danger' });
//       }
//     };
//     fetchCategories();
//   }, []);

//   const onSubmit = async (data) => {
//     setLoading(true);

//     const isValid = await trigger();
//     if (!isValid) {
//       setNotification({ message: 'Please fill in all required fields.', type: 'danger' });
//       setLoading(false);
//       return;
//     }

//     try {
//       const formData = new FormData();

//       formData.append('productName', data.productName);
//       formData.append('description', data.description);
//       formData.append('price', data.price);
//       formData.append('catId', selectedCategoryId); // Use selected category ID
//       formData.append('subCatId', data.subCatId); // Manually set subCatId
//       formData.append('slug', data.slug); // Added the slug field
//       if (image) {
//         formData.append('image', image);
//       }

//       const response = await addProduct(formData);

//       if (response?.productId) {
//         setNotification({ message: 'Product added successfully!', type: 'success' });
//       } else {
//         setNotification({ message: 'Failed to add product.', type: 'danger' });
//       }
//     } catch (error) {
//       setNotification({ message: 'An error occurred while adding the product.', type: 'danger' });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleImageUpload = (event) => {
//     const file = event.target.files[0];
//     if (file && file.size <= 256 * 1024) { // 256 KB
//       setImage(file);
//       setImageError('');
//     } else {
//       setImageError('Image size must be less than 256 KB.');
//       setImage(null);
//     }
//   };

//   const handleCategoryChange = (event) => {
//     const selectedCategory = event.target.value;
//     const category = categories.find(cat => cat.categoryName === selectedCategory);
//     if (category) {
//       setSelectedCategoryId(category.categoryId); // Set the selected category ID
//       setValue('catId', category.categoryId); // Set the category ID in the form
//     }
//   };

//   return (
//     <div className="dashboard-influence">
//       {loading && <Loader />}
//       <div className="container-fluid dashboard-content">
//         <div className="row">
//           <div className="col-xl-12">
//             <h3 className="mb-2">Add Product</h3>
//           </div>
//         </div>

//         <div className="row">
//           <div className="col-xl-12">
//             <div className="card">
//               <div className="card-body">
//                 {notification.message && (
//                   <div className={`alert alert-${notification.type}`} role="alert">
//                     {notification.message}
//                   </div>
//                 )}

//                 <form onSubmit={handleSubmit(onSubmit)}>
//                   <div className="form-group">
//                     <label htmlFor="productName">Product Name<span style={{ color: '#FF0000' }}> *</span></label>
//                     <input
//                       id="productName"
//                       {...register('productName', { required: 'Product name is required' })}
//                       type="text"
//                       className={`form-control ${errors.productName ? 'is-invalid' : ''}`}
//                     />
//                     {errors.productName && <div className="invalid-feedback">{errors.productName.message}</div>}
//                   </div>

//                   <div className="form-group">
//                     <label htmlFor="slug">Slug<span style={{ color: '#FF0000' }}> *</span></label>
//                     <input
//                       id="slug"
//                       {...register('slug', { required: 'Slug is required' })}
//                       type="text"
//                       className={`form-control ${errors.slug ? 'is-invalid' : ''}`}
//                     />
//                     {errors.slug && <div className="invalid-feedback">{errors.slug.message}</div>}
//                   </div>

//                   <div className="form-group">
//                     <label htmlFor="description">Description<span style={{ color: '#FF0000' }}> *</span></label>
//                     <textarea
//                       id="description"
//                       {...register('description', { required: 'Description is required' })}
//                       className={`form-control ${errors.description ? 'is-invalid' : ''}`}
//                     />
//                     {errors.description && <div className="invalid-feedback">{errors.description.message}</div>}
//                   </div>

//                   <div className="form-group">
//                     <label htmlFor="category">Category<span style={{ color: '#FF0000' }}> *</span></label>
//                     <select
//                       id="category"
//                       className="form-control"
//                       onChange={handleCategoryChange}
//                     >
//                       <option value="">Select Category</option>
//                       {categories.map(category => (
//                         <option key={category.categoryId} value={category.categoryName}>
//                           {category.categoryName}
//                         </option>
//                       ))}
//                     </select>
//                   </div>

//                   <div className="form-group">
//                     <label htmlFor="catId">Category ID</label>
//                     <input
//                       id="catId"
//                       {...register('catId')}
//                       value={selectedCategoryId} // Automatically set the category ID
//                       readOnly
//                       className="form-control"
//                     />
//                   </div>

//                   <div className="form-group">
//                     <label htmlFor="subCatId">Sub-Category ID<span style={{ color: '#FF0000' }}> *</span></label>
//                     <input
//                       id="subCatId"
//                       {...register('subCatId', { required: 'Sub-category ID is required' })}
//                       defaultValue="" // Manually input a default value for subCatId
//                       className={`form-control ${errors.subCatId ? 'is-invalid' : ''}`}
//                     />
//                     {errors.subCatId && <div className="invalid-feedback">{errors.subCatId.message}</div>}
//                   </div>

//                   <div className="form-group">
//                     <label htmlFor="image">Upload Image</label>
//                     <input
//                       id="image"
//                       type="file"
//                       accept="image/*"
//                       className={`form-control ${imageError ? 'is-invalid' : ''}`}
//                       onChange={handleImageUpload}
//                     />
//                     {image && <p>Image: {image.name}</p>}
//                     {imageError && <div className="invalid-feedback">{imageError}</div>}
//                   </div>

//                   <button type="submit" className="btn btn-primary" disabled={loading}>
//                     {loading ? 'Adding...' : 'Add Product'}
//                   </button>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddProduct;

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "../../services/Loader";
import {
  getAllCategory,
  fetchVendorVariantProduct,
  addProduct,
  fetchVariantProduct,
  fetchImageProduct,
} from "../../services/APIService";

const AddProduct = ({ vendorId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [imageError, setImageError] = useState("");
  const [image, setImage] = useState(null);
  console.log("image", image);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [prodId, setprodId] = useState(0);
  console.log("prodId", prodId);
  const [vendorVariant, setVendorVariant] = useState({
    idVendorVariant: 0,
    price: 0,
    discount: 0,
    adminCommission: 0,
    availableQty: 0,
    variantId: 0,
    vendorId: 0,
    crud: "",
    status: "VVA",
    toBeShipped: 0,
    shipped: 0,
    delivered: 0,
    toBeReturned: 0,
  });
  console.log("vendorVariant.=", vendorVariant);
  const [step, setStep] = useState(1);
  const [variants, setVariants] = useState([]); // State to store fetched variants
  const [product, setProduct] = useState({ variation: "" });
  const [variantImages, setVariantImages] = useState([]); // State to store variant images
  console.log("product", product);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getAllCategory();
        if (response) {
          setCategories(response);
        }
      } catch (error) {
        console.error("Error fetching categories", error);
        setNotification({
          message: "Failed to fetch categories",
          type: "danger",
        });
      }
    };
    fetchCategories();
  }, []);

  const handleCategoryChange = async (event) => {
    const selectedCategory = event.target.value;
    const category = categories.find(
      (cat) => cat.categoryName === selectedCategory
    );
    if (category) {
      setSelectedCategoryId(category.categoryId);
      setValue("catId", category.categoryId);
      try {
        // Fetch subcategories for the selected category
        const subCategoryResponse = await getAllCategory(category.categoryId);
        if (subCategoryResponse) {
          setSubCategories(subCategoryResponse);
        }
      } catch (error) {
        console.error("Error fetching subcategories", error);
        setNotification({
          message: "Failed to fetch subcategories",
          type: "danger",
        });
      }
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const uploadProductImage = async (entityId, entityType) => {
    // if (!image) return;

    const formData = new FormData();
    // formData.append("image", image);
    formData.append("entityType", entityType);
    formData.append("entityId", entityId);
    formData.append("imageFile", image);
    formData.append("IsCoverImage", true);

    try {
      console.log("formData", formData);
      const response = await fetchImageProduct(formData);
      if (response) {
        setNotification({
          message: "Image uploaded successfully!",
          type: "success",
        });
      }
    } catch (error) {
      console.error("Error uploading image", error);
      setNotification({ message: "Failed to upload image", type: "danger" });
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const isValid = await trigger();
    if (!isValid) {
      setNotification({
        message: "Please fill in all required fields.",
        type: "danger",
      });
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("productName", data.productName);
      formData.append("description", data.description);
      formData.append("price", data.price);
      formData.append("catId", selectedCategoryId);
      formData.append("slug", data.slug);
      formData.append("subCatId", data.subCatId);
      // formData.append("image", data.imagefile);

      const response = await addProduct(formData);
      if (response?.productId) {
        setprodId(response?.productId);
        setNotification({
          message: "Product added successfully!",
          type: "success",
        });
        // Upload the image with entity type "V" for Product
        await uploadProductImage(response.productId, "V");

        const payload = {
          productId: response?.productId,
          variation: product?.variation,
        };

        const res = await fetchVariantProduct(payload);
        console.log("res============", res);
        setVendorVariant((prevState) => ({
          ...prevState,
          variantId: res?.variantId, // Automatically map the first variant
        }));
        if (res?.length > 0) {
          setVariants(res); // Store the fetched variants
          // fetchImages(res[0].variantId); // Fetch images for the first variant
        } else {
          setNotification({
            message: "No variants found for this product.",
            type: "warning",
          });
        }

        // setVendorVariant((prevState) => ({
        //   ...prevState,
        //   prodId: response.productId, // Assign prodId from the product response
        // }));
        // fetchVariant(prodId); // Use the fetchVariant function
        setStep(2);
      } else {
        setNotification({ message: "Failed to add product.", type: "danger" });
      }
    } catch (error) {
      console.error("Error adding product:", error);
      setNotification({
        message: "An error occurred while adding the product.",
        type: "danger",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchVariant = async (prodId) => {
    console.log("prodId in fetchVariant", prodId);
    setLoading(true); // Set loading state
    try {
      const payload = {
        productId: prodId,
        variation: product?.variation,
      };

      const response = await fetchVariantProduct(payload);
      console.log("response", response);
      if (response?.length > 0) {
        setVariants(response); // Store the fetched variants
        setVendorVariant((prevState) => ({
          ...prevState,
          variantId: response[0].variantId, // Automatically map the first variant
        }));
        // fetchImages(response[0].variantId); // Fetch images for the first variant
      } else {
        setNotification({
          message: "No variants found for this product.",
          type: "warning",
        });
      }
    } catch (error) {
      console.error("Error save variant:", error);
      setNotification({ message: "Failed to save variants.", type: "danger" });
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  // const fetchImages = async (variantId) => {
  //   setLoading(true); // Set loading state
  //   try {
  //     const response = await fetchImageProduct(variantId); // Fetch the variant images
  //     if (response) {
  //       setVariantImages(response); // Store the fetched images
  //     } else {
  //       setNotification({
  //         message: "No images found for this variant.",
  //         type: "warning",
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error save variant images:", error);
  //     setNotification({ message: "Failed to save images.", type: "danger" });
  //   } finally {
  //     setLoading(false); // Reset loading state
  //   }
  // };

  const handleSubmitVendorVariant = async (e) => {
    e.preventDefault();
    const isValid = await trigger();
    if (!isValid) {
      setNotification({
        message: "Please fill in all required fields.",
        type: "danger",
      });
      return;
    }

    // Check for negative price
    if (parseFloat(vendorVariant.price) < 0) {
      setNotification({ message: "Price cannot be negative.", type: "danger" });
      return;
    }

    try {
      const data = {
        ...vendorVariant,
        vendorId: vendorId,
        variantId: vendorVariant.variantId || vendorVariant.prodId, // Set variantId
        variation: product?.variation,
      };

      const response = await fetchVendorVariantProduct(data);
      if (response) {
        setNotification({
          message: "Vendor variant saved successfully!",
          type: "success",
        });
        setStep(2);
      } else {
        setNotification({
          message: "Failed to save vendor variant.",
          type: "danger",
        });
      }
    } catch (error) {
      console.error("Error saving vendor variant:", error);
      setNotification({
        message:
          error.response?.data?.message || "Failed to save vendor variant.",
        type: "danger",
      });
    }
  };

  return (
    <div className="dashboard-influence">
      {loading && <Loader />}
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12">
            <h3 className="mb-2">
              {step === 1 ? "Add Product" : "Add Variant"}
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                {notification.message && (
                  <div
                    className={`alert alert-${notification.type}`}
                    role="alert"
                  >
                    {notification.message}
                  </div>
                )}

                {step === 1 && (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                      <label htmlFor="productName">
                        Product Name<span style={{ color: "#FF0000" }}> *</span>
                      </label>
                      <input
                        id="productName"
                        {...register("productName", {
                          required: "Product name is required",
                        })}
                        type="text"
                        className={`form-control ${
                          errors.productName ? "is-invalid" : ""
                        }`}
                      />
                      {errors.productName && (
                        <div className="invalid-feedback">
                          {errors.productName.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="slug">
                        Slug<span style={{ color: "#FF0000" }}> *</span>
                      </label>
                      <input
                        id="slug"
                        {...register("slug", { required: "Slug is required" })}
                        type="text"
                        className={`form-control ${
                          errors.slug ? "is-invalid" : ""
                        }`}
                      />
                      {errors.slug && (
                        <div className="invalid-feedback">
                          {errors.slug.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="description">
                        Description<span style={{ color: "#FF0000" }}> *</span>
                      </label>
                      <textarea
                        id="description"
                        {...register("description", {
                          required: "Description is required",
                        })}
                        className={`form-control ${
                          errors.description ? "is-invalid" : ""
                        }`}
                      />
                      {errors.description && (
                        <div className="invalid-feedback">
                          {errors.description.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="category">
                        Category<span style={{ color: "#FF0000" }}> *</span>
                      </label>
                      <select
                        id="category"
                        className="form-control"
                        onChange={handleCategoryChange}
                      >
                        <option value="">Select Category</option>
                        {categories.map((category) => (
                          <option
                            key={category.categoryId}
                            value={category.categoryName}
                          >
                            {category.categoryName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="subCatId">
                        Sub-Category<span style={{ color: "#FF0000" }}> *</span>
                      </label>
                      <select
                        id="subCatId"
                        {...register("subCatId", {
                          required: "Sub-category is required",
                        })}
                        className={`form-control ${
                          errors.subCatId ? "is-invalid" : ""
                        }`}
                      >
                        <option value="">Select Sub-Category</option>
                        {subCategories.map((subCategory) => (
                          <option
                            key={subCategory.categoryId}
                            value={subCategory.categoryId}
                          >
                            {subCategory.categoryName}
                          </option>
                        ))}
                      </select>
                      {errors.subCatId && (
                        <div className="invalid-feedback">
                          {errors.subCatId.message}
                        </div>
                      )}
                    </div>

                    {/* Image Upload */}
                    <div className="form-group">
                      <label htmlFor="image">Product Image</label>
                      <input
                        id="image"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="form-control"
                      />
                    </div>

                    <label htmlFor="variation">
                      Variation<span style={{ color: "#FF0000" }}> *</span>
                    </label>
                    <input
                      id="variation"
                      {...register("variation", {
                        required: "Variation is required",
                      })}
                      value={product.variation}
                      onChange={(e) =>
                        setProduct({ ...product, variation: e.target.value })
                      }
                      placeholder="e.g.. color, size, shape"
                      className={`form-control ${
                        errors.variation ? "is-invalid" : ""
                      } mb-2`}
                    />
                    {errors.variation && (
                      <div className="invalid-feedback">
                        {errors.variation.message}
                      </div>
                    )}

                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        Add Product
                      </button>
                    </div>
                  </form>
                )}

                {/* <div className="form-group">
                       
                    </div>
                    
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">Add Product</button>
                    </div> */}

                {step === 2 && (
                  <>
                    {/* <ul>
                      {variants.map(variant => (
                        <li key={variant.variantId}>
                          Variant ID: {variant.variantId}, Name: {variant.variation}
                        </li>
                      ))}
                    </ul> */}

                    <form onSubmit={handleSubmitVendorVariant}>
                      <div className="form-group">
                        <label htmlFor="price">
                          Price<span style={{ color: "#FF0000" }}> *</span>
                        </label>
                        <input
                          type="number"
                          value={vendorVariant.price}
                          onChange={(e) =>
                            setVendorVariant({
                              ...vendorVariant,
                              price: e.target.value,
                            })
                          }
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="discount">
                          Discount<span style={{ color: "#FF0000" }}> *</span>
                        </label>
                        <input
                          type="number"
                          value={vendorVariant.discount}
                          onChange={(e) =>
                            setVendorVariant({
                              ...vendorVariant,
                              discount: e.target.value,
                            })
                          }
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="availableQty">
                          Available Quantity
                          <span style={{ color: "#FF0000" }}> *</span>
                        </label>
                        <input
                          type="number"
                          value={vendorVariant.availableQty}
                          onChange={(e) =>
                            setVendorVariant({
                              ...vendorVariant,
                              availableQty: e.target.value,
                            })
                          }
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <select
                          className="form-control"
                          value={vendorVariant.status}
                          onChange={(e) =>
                            setVendorVariant({
                              ...vendorVariant,
                              status: e.target.value,
                            })
                          }
                        >
                          <option value="VVA">Active</option>
                          <option value="">In-Active</option>
                        </select>
                      </div>

                      {/* <div className="form-group">
                      <label htmlFor="variation">Variation<span style={{ color: '#FF0000' }}> *</span></label>
                      <input
                        type="text"
                        value={vendorVariant.variation}
                        onChange={e => fetchVariant({ ...vendorVariant, variation: e.target.value })}
                        className="form-control"
                        placeholder="e.g. Size, Color, etc."
                        required
                      />
                    </div> */}

                      {/* <div className="form-group">
                        <label htmlFor="variantImage">
                          Upload Variant Image
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="variantImage"
                          onChange={handleImageUpload}
                        />
                        {imageError && (
                          <div className="text-danger">{imageError}</div>
                        )}
                      </div> */}

                      <div className="form-group">
                        <button type="submit" className="btn btn-primary">
                          Submit Vendor Variant
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
