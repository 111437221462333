import React, { useState, useEffect } from "react";
import {
  deleteOrder,
  getOrderStatuses,
  getViewOrders,
  updateOrderItemStatus,
  getAddress,
} from "../../services/APIService";
import Loader from "../../services/Loader";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";

export default function Orders() {
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");
  const [filteredData, setFilteredData] = useState(orderData);

  console.log("filterStatus", filterStatus);
  console.log("msgText", msgText);

  const [search, setSearch] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [statusDataForFliter, setStatusDataForFliter] = useState([]);

  const [statusData, setStatusData] = useState([]);
  const [orderDetails, setOrderDetails] = useState();
  const [subTotal, setSubtotal] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [customerAddress, setCustomerAddress] = useState(null);
  console.log("customerAddress", customerAddress);
  console.log("selectedOrder", selectedOrder);
  console.log("statusData", statusData);

  useEffect(() => {
    loadOrders();
    fetchOrderStatuses();
  }, []);

  const loadOrders = async () => {
    try {
      setIsLoading(true);
      const response = await getViewOrders(); // Fetching orders using getViewOrders
      setOrderData(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  // const fetchOrderStatuses = async () => {
  //   try {
  //     const response = await getOrderStatuses();
  //     setStatusDataForFliter(response);

  //     // Find the status object for the current orderStatus from selectedOrder
  //     const selectedOrderStatus = response.find(
  //       (status) => status.name === selectedOrder.orderStatus
  //       //(status) => status.name === selectedOrder.orderStatus && status.entity === selectedOrder.entity
  //     );
  //     console.log("selectedOrderStatus", selectedOrderStatus);

  //     if (selectedOrderStatus) {
  //       // Get the sequence value of the selected orderStatus
  //       const selectedSequence = selectedOrderStatus.sequence;
  //       // Filter statusData for statuses with a higher sequence value
  //       const filteredStatusData = response.filter(
  //         (status) => status.sequence >= selectedSequence
  //       );

  //       // Update the state with the filtered status data
  //       setStatusData(filteredStatusData);
  //     } else {
  //       // Handle the case where the orderStatus code doesn't match any status in the response
  //       console.error("Selected order status not found in status data");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching statuses:", error);
  //   }
  // };

  const fetchOrderStatuses = async () => {
    try {
      const response = await getOrderStatuses();
      // Add default entity as "Order" for filtering
      const entity = selectedOrder.entity || "Order";
      setStatusDataForFliter(response);

      // Find the status object for the current orderStatus and entity
      const selectedOrderStatus = response.find(
        (status) =>
          status.name === selectedOrder.orderStatus && status.entity === entity
      );
      console.log("selectedOrderStatus", selectedOrderStatus);

      if (selectedOrderStatus) {
        // Get the sequence value of the selected orderStatus
        const selectedSequence = selectedOrderStatus.sequence;

        // Filter statusData for statuses with the same entity and a higher sequence value
        const filteredStatusData = response.filter(
          (status) =>
            status.sequence >= selectedSequence && status.entity === entity
        );

        // Update the state with the filtered status data
        setStatusData(filteredStatusData);
      } else {
        console.error("Selected order status not found in status data");
      }
    } catch (error) {
      console.error("Error fetching statuses:", error);
    }
  };

  useEffect(() => {
    fetchOrderStatuses();
  }, [selectedOrder]);



  // Function for calculating discount amount
  const discountedAmt = (data) => {
    var discountAmt = (data.price * data.discountAmount) / 100;
    return data.price - discountAmt;
  };

  const DetailsCalculation = (data) => {
    let oDetails = data?.orderDetails;
    var sTotal = 0;
    for (let i = 0; i < oDetails?.length; i++) {
      var total = discountedAmt(oDetails[i]);
      sTotal += total * oDetails[i].qty;
    }
    setSubtotal(sTotal);
    setNetTotal(sTotal + data.shippingCost);
  };

  const viewDetails = async (row) => {
    setSelectedOrder(row);
    setOrderDetails(row.orderDetails);
    DetailsCalculation(row);

    // Fetch customer address details
    console.log("shippingAddressId", row.shippingAddressId);
    try {
      const addressResponse = await getAddress(row.shippingAddressId);
      console.log("Address Response:", addressResponse); // Log the response
      if (addressResponse && addressResponse.length > 0) {
        setCustomerAddress(addressResponse[0]); // Accessing the first item
      } else {
        setCustomerAddress(null);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedOrder(null);
    setCustomerAddress(null); // Reset address details
  };

  const editOrderDetails = (row) => {
    setSelectedOrder({ ...row });
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setSelectedOrder(null);
  };

  const deleteOrderHandler = async (orderId) => {
    if (window.confirm("Are you sure you want to delete this order?")) {
      try {
        const response = await deleteOrder(orderId);
        console.log("response", response);
        setMsgText(response.message); // Show the message from the server

        if (response && response.message) {
          if (response.message.includes("cannot be deleted")) {
            setMsgClass("alert alert-danger");
            setMsgText(response.message); // Show the message from the server
            setShowMsg(true);
          } else {
            setMsgClass("alert alert-success");
            // setMsgText("Order deleted successfully!");
            setShowMsg(true);
            loadOrders(); // Reload orders after deletion
          }
        } else {
          setMsgClass("alert alert-danger");
          setMsgText("Unexpected response structure from server.");
          setShowMsg(true);
          console.error("Unexpected response:", response);
        }
      } catch (error) {
        // Handling error from deleteOrder function
        setMsgClass("alert alert-danger");
        setMsgText(
          "Error deleting order: " +
            (error.response?.data?.message || "Unknown error")
        );
        setShowMsg(true);
        console.error("Error deleting order:", error);
      }
    }
  };

  const handleUpdateOrder = async () => {
    if (
      !selectedOrder.paymentMethode ||
      !selectedOrder.status ||
      !selectedOrder.orderDetails ||
      !selectedOrder.shippingAddressId ||
      !selectedOrder.billingAddressId
    ) {
      setMsgClass("alert alert-danger");
      setMsgText("All required fields must be filled.");
      setShowMsg(true);
      return;
    }

    if (selectedOrder.status === "ODC") {
      setMsgClass("alert alert-danger");
      setMsgText("No changes allowed in Order Status for ODC orders.");
      setShowMsg(true);
      return;
    }

    const updatedOrderData = {
      orderId: selectedOrder.orderId,
      userId: selectedOrder.userId,
      orderNumber: selectedOrder.orderNumber,
      couponName: selectedOrder.couponName,
      shippingCost: selectedOrder.shippingCost,
      paymentType: selectedOrder.paymentType,
      status: selectedOrder.status,
      comment: selectedOrder.comment,
      createdAt: selectedOrder.createdAt,
      updatedAt: new Date().toISOString(),
      shippingAddressId: selectedOrder.shippingAddressId,
      billingAddressId: selectedOrder.billingAddressId,
      createdBy: selectedOrder.createdBy,
      updatedBy: selectedOrder.updatedBy,
      orderDetails: selectedOrder.orderDetails,
      crud: "U",
      paymentMethode: selectedOrder.paymentMethode,
      orderStatus: selectedOrder.status,
    };

    try {
      await updateOrderItemStatus(updatedOrderData);

      // Optimistically update the local state
      const updatedOrders = orderData.map((order) =>
        order.orderId === selectedOrder.orderId
          ? { ...order, ...updatedOrderData }
          : order
      );
      setOrderData(updatedOrders);

      // Refetch orders to ensure UI sync with backend
      loadOrders();

      setMsgClass("alert alert-success");
      setMsgText("Order updated successfully!");
      setShowMsg(true);
      closeEditModal();
    } catch (error) {
      const errorMsg = error.response?.data?.message || "Something went wrong!";
      setMsgClass("alert alert-danger");
      setMsgText(`Error updating order: ${errorMsg}`);
      setShowMsg(true);
      console.error("Error updating order:", error);
    }
  };

  const columns = [
    {
      name: <b>#</b>,
      selector: (row, i) => i + 1,
      sortable: true,
      width: "50px",
    },
    {
      name: <b>User ID</b>,
      selector: (row) => row.userId,
      sortable: true,
      width: "100px",
    },
    {
      name: <b>Order ID</b>,
      selector: (row) => row.orderId,
      sortable: true,
      width: "150px",
    },
    {
      name: <b>Order No</b>,
      selector: (row) => row.orderNumber,
      sortable: true,
      width: "150px",
    },
    {
      name: <b>Status</b>,
      selector: (row) => row.orderStatus,
      sortable: true,
      width: "120px",
    },
    {
      name: <b>Payment Mode</b>,
      selector: (row) => row.paymentMethode,
      sortable: true,
      width: "120px",
    },
    {
      name: <b>Actions</b>,
      cell: (row) => (
        <div>
          <i
            onClick={() => viewDetails(row)}
            className="fas fa-eye mr-3 text-info fa-lg"
          ></i>
          <i
            onClick={() => editOrderDetails(row)}
            className="fas fa-edit mr-3 text-primary fa-lg"
          ></i>
          <i
            onClick={() => deleteOrderHandler(row)}
            className="fas fa-trash-alt text-danger fa-lg"
          ></i>
        </div>
      ),
      width: "150px",
    },
  ];

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    console.log("Filter status updated:", filterStatus);
    console.log("orderData", orderData);
    // Filter order data based on filterStatus and search input
    const filtered = orderData.filter((item) => {
      const matchesStatus = filterStatus ? item?.status === filterStatus : true;
      const matchesSearch =
        item.orderNumber
          ?.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item.orderId?.toString().toLowerCase().includes(search.toLowerCase()) ||
        item.status?.name
          ?.toString()
          .toLowerCase()
          .includes(search.toLowerCase());

      return matchesStatus && matchesSearch;
    });

    setFilteredData(filtered);
  }, [filterStatus, search, orderData]);

  return (
    <div className="dashboard-influence">
      {isLoading && <Loader />}
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="page-header">
              <h3 className="mb-2">Order List</h3>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                {showMsg && (
                  <div className={msgClass} role="alert">
                    <strong>{msgText}</strong>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                )}

                {orderData?.length > 0 ? (
                  <div className="table-responsive">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-3 d-flex justify-content-between">
                        <div className="input-group">
                          <span className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fas fa-search"></i>
                            </span>
                          </span>
                          <input
                            placeholder="Search by order id, order no"
                            className="form-control"
                            value={search}
                            onChange={handleSearch}
                          />
                        </div>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-3 d-flex justify-content-between">
                        <label></label>
                        <select
                          type="text"
                          className="form-control"
                          style={{ width: "150px" }}
                          value={filterStatus || ""}
                          onChange={(e) => setFilterStatus(e.target.value)}
                        >
                          <option value="">Select Status</option>
                          {statusDataForFliter.map((status) => (
                            <option key={status.statusId} value={status.code}>
                              {status.code} - {status.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <DataTable
                      className="table"
                      columns={columns}
                      data={filteredData}
                      pagination
                      highlightOnHover
                      selectableRows
                    />
                  </div>
                ) : (
                  <h3 className="card-header text-center mt-3">
                    Data not available..!
                  </h3>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for editing order */}
      <Modal show={showEditModal} onHide={closeEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrder ? (
            <div>
              <div className="form-group">
                <label>Order Status</label>
                <select
                  type="text"
                  className="form-control"
                  value={selectedOrder.status}
                  onChange={(e) =>
                    setSelectedOrder({
                      ...selectedOrder,
                      status: e.target.value,
                    })
                  }
                >
                  {statusData.map((status) => (
                    <option key={status.statusId} value={status.code}>
                      {status.code} - {status.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Payment Mode</label>
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  value={selectedOrder.paymentMethode}
                  onChange={(e) =>
                    setSelectedOrder({
                      ...selectedOrder,
                      paymentMethode: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          ) : (
            "No order selected"
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={closeEditModal}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleUpdateOrder}>
            Update Changes
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal for viewing order details */}
      <Modal show={showModal} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderDetails ? (
            <>
              <div className="row">
                <div className="col-sm-6">
                  <h5>Order Information</h5>
                  <p>Order No: {selectedOrder?.orderNumber}</p>
                  <p>Status: {selectedOrder?.orderStatus}</p>
                  <p>Payment Method: {selectedOrder?.paymentMethode}</p>
                  <p>Order Date: {selectedOrder?.createdAt}</p>
                </div>
                <div className="col-sm-6">
                  <h5>Customer Information</h5>
                  {customerAddress ? (
                    <div>
                      <p>
                        <strong>Street Address:</strong>{" "}
                        {`${customerAddress.streetAddress}, ${customerAddress.landmark}, ${customerAddress.pincode}`}
                      </p>
                    </div>
                  ) : (
                    <p>Loading customer information...</p>
                  )}
                </div>
              </div>

              <h5>Order Items</h5>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.productName}</td>
                      <td>{item.qty}</td>
                      <td>{discountedAmt(item).toFixed(2)}</td>
                      <td>{item.orderDetailStatus}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="row">
                <div className="col-md-6">
                  <h5>Subtotal: Rs.{subTotal.toFixed(2)}</h5>
                  <h5>Shipping: Rs.{selectedOrder?.shippingCost}</h5>
                  <h5>Net Total: Rs.{netTotal.toFixed(2)}</h5>
                </div>
              </div>
            </>
          ) : (
            <p>No details available</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={closeModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

// import React, { useState, useEffect } from "react";
// import { getAllOrders, deleteOrder, updateOrder, getOrderStatuses, getViewOrders, updateOrderItemStatus } from "../../services/APIService";
// import Loader from "../../services/Loader";
// import DataTable from "react-data-table-component";
// import { Modal } from "react-bootstrap";

// export default function Orders() {
//   const [isLoading, setIsLoading] = useState(false);
//   const [showMsg, setShowMsg] = useState(false);
//   const [msgClass, setMsgClass] = useState("");
//   const [msgText, setMsgText] = useState("");
//   const [orderData, setOrderData] = useState([]);
//   const [search, setSearch] = useState("");
//   const [selectedOrder, setSelectedOrder] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [showEditModal, setShowEditModal] = useState(false);
//   const [statusData, setStatusData] = useState([]); // New state for order statuses

//   useEffect(() => {
//     loadOrders();
//     fetchOrderStatuses();
//   }, []);

//   const loadOrders = async () => {
//     try {
//       setIsLoading(true);
//       const response = await getAllOrders();
//       setOrderData(response);
//       setIsLoading(false);
//     } catch (error) {
//       setIsLoading(false);
//       console.log(error);
//     }
//   };

//   const fetchOrderStatuses = async () => {
//     try {
//       const response = await getOrderStatuses(); // API call to fetch statuses
//       setStatusData(response);
//     } catch (error) {
//       console.error("Error fetching statuses:", error);
//     }
//   };

//   // Function to view order details
//   const viewDetails = async (row) => {
//     try {
//       const orderDetails = await getViewOrders(row.orderId); // Fetch order details
//       console.log("Order Details:", orderDetails);
//       setSelectedOrder(orderDetails);
//       setShowModal(true);
//     } catch (error) {
//       console.error("Error fetching order details:", error);
//     }
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     setSelectedOrder(null);
//   };

//   // Function to open modal and edit order details
//   const editOrderDetails = (row) => {
//     setSelectedOrder({ ...row });
//     setShowEditModal(true);
//   };

//   const closeEditModal = () => {
//     setShowEditModal(false);
//     setSelectedOrder(null);
//   };

//   // Function to delete an order with confirmation
//   const deleteOrderHandler = async (row) => {
//     if (window.confirm("Are you sure you want to delete this order?")) {
//       try {
//         await deleteOrder(row);
//         setMsgClass("alert alert-success");
//         setMsgText("Order deleted successfully!");
//         setShowMsg(true);
//         loadOrders(); // Reload orders after deletion
//       } catch (error) {
//         setMsgClass("alert alert-danger");
//         setMsgText("Error deleting order!");
//         setShowMsg(true);
//         console.error(error);
//       }
//     }
//   };

//   const handleUpdateOrder = async () => {
//     // Ensure required fields are filled
//     if (!selectedOrder.paymentMethode || !selectedOrder.status) {
//       setMsgClass("alert alert-danger");
//       setMsgText("Payment Method and Order Status are required.");
//       setShowMsg(true);
//       return;
//     }

//     try {
//       // Make API call to update the order in the backend
//       await updateOrderItemStatus(selectedOrder);

//       // Update the local state optimistically
//       const updatedOrders = orderData.map((order) =>
//         order.orderId === selectedOrder.orderId ? { ...order, ...selectedOrder } : order
//       );
//       setOrderData(updatedOrders);

//       // Show success message
//       setMsgClass("alert alert-success");
//       setMsgText("Order updated successfully!");
//       setShowMsg(true);

//       // Close the edit modal
//       closeEditModal();
//     } catch (error) {
//       setMsgClass("alert alert-danger");
//       setMsgText("Error updating order!");
//       setShowMsg(true);
//       console.error(error);
//     }
//   };

//   const columns = [
//     {
//       name: <b>#</b>,
//       selector: (row, i) => i + 1,
//       sortable: true,
//       width: "50px",
//     },
//     {
//       name: <b>User ID</b>,
//       selector: (row) => row.userId,
//       sortable: true,
//       width: "100px",
//     },
//     {
//       name: <b>Order ID</b>,
//       selector: (row) => row.orderId,
//       sortable: true,
//       width: "150px",
//     },
//     {
//       name: <b>Order No</b>,
//       selector: (row) => row.orderNumber,
//       sortable: true,
//       width: "150px",
//     },
//     {
//       name: <b>Status</b>,
//       selector: (row) => row.status,
//       sortable: true,
//       width: "120px",
//     },
//     {
//       name: <b>Payment Mode</b>,
//       selector: (row) => row.paymentMethode,
//       sortable: true,
//       width: "150px",
//     },
//     {
//       name: <b>Actions</b>,
//       cell: (row) => (
//         <div>
//           <i
//             onClick={() => viewDetails(row)} // Show details on click
//             className="fas fa-eye mr-3 text-info fa-lg" // View icon
//           ></i>
//           <i
//             onClick={() => editOrderDetails(row)} // Edit action
//             className="fas fa-edit mr-3 text-primary fa-lg"
//           ></i>
//           <i
//             onClick={() => deleteOrderHandler(row)} // Delete action
//             className="fas fa-trash-alt text-danger fa-lg"
//           ></i>
//         </div>
//       ),
//       width: "150px",
//     },
//   ];

//   const handleSearch = (e) => {
//     setSearch(e.target.value);
//   };

//   const filteredData = orderData?.filter(
//     (item) =>
//       item.orderNumber.toLowerCase().includes(search.toLowerCase()) ||
//       item.orderId.toLowerCase().includes(search.toLowerCase()) ||
//       item.status.toLowerCase().includes(search.toLowerCase())
//   );

//   return (
//     <div className="dashboard-influence">
//       {isLoading && <Loader />}
//       <div className="container-fluid dashboard-content">
//         <div className="row">
//           <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
//             <div className="page-header">
//               <h3 className="mb-2">Order List</h3>
//             </div>
//           </div>
//         </div>

//         <div className="row">
//           <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
//             <div className="card">
//               <div className="card-body">
//                 {showMsg && (
//                   <div className={msgClass} role="alert">
//                     <strong>{msgText}</strong>
//                     <button
//                       type="button"
//                       className="close"
//                       data-dismiss="alert"
//                       aria-label="Close"
//                     >
//                       <span aria-hidden="true">×</span>
//                     </button>
//                   </div>
//                 )}

//                 {orderData?.length > 0 ? (
//                   <div className="table-responsive">
//                     <div className="row">
//                       <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
//                         <div className="input-group">
//                           <span className="input-group-prepend">
//                             <span className="input-group-text">
//                               <i className="fas fa-search"></i>
//                             </span>
//                           </span>
//                           <input
//                             placeholder="Search by name, order id, order no"
//                             className="form-control"
//                             value={search}
//                             onChange={handleSearch}
//                           />
//                         </div>
//                       </div>
//                     </div>

//                     <DataTable
//                       className="table"
//                       columns={columns}
//                       data={filteredData}
//                       pagination
//                       highlightOnHover
//                       selectableRows
//                     />
//                   </div>
//                 ) : (
//                   <h3 className="card-header text-center mt-3">
//                     Data not available..!
//                   </h3>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Modal for editing order */}
//       <Modal show={showEditModal} onHide={closeEditModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>Edit Order</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {selectedOrder ? (
//             <div>
//               <div className="form-group">
//                 <label>Order Status</label>
//                 <select
//                   type="text"
//                   className="form-control"
//                   value={selectedOrder.status}
//                   onChange={(e) =>
//                     setSelectedOrder({
//                       ...selectedOrder,
//                       status: e.target.value,
//                     })
//                   }
//                 >
//                   {statusData.map((status) => (
//                     <option key={status.id} value={status.name}>
//                       {status.code}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div className="form-group">
//                 <label>Payment Method</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   readOnly
//                   value={selectedOrder.paymentMethode}
//                   onChange={(e) =>
//                     setSelectedOrder({
//                       ...selectedOrder,
//                       paymentMethode: e.target.value,
//                     })
//                   }
//                 />
//               </div>
//             </div>
//           ) : (
//             <p>Loading...</p>
//           )}
//         </Modal.Body>
//         <Modal.Footer>
//           <button className="btn btn-primary" onClick={handleUpdateOrder}>
//             Update Order
//           </button>
//           <button className="btn btn-secondary" onClick={closeEditModal}>
//             Close
//           </button>
//         </Modal.Footer>
//       </Modal>

//       {/* Modal for viewing order details */}
//       <Modal show={showModal} onHide={closeModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>Order Details</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {selectedOrder ? (
//             <div>
//               <p>
//                 <strong>Order ID:</strong> {selectedOrder.orderId}
//               </p>
//               <p>
//                 <strong>Product Name:</strong> {selectedOrder.productName}
//               </p>
//               <p>
//                 <strong>Status:</strong> {selectedOrder.status}
//               </p>
//               <p>
//                 <strong>Price:</strong> ${selectedOrder.price}
//               </p>
//               <p>
//                 <strong>Payment Method:</strong> {selectedOrder.paymentMethode}
//               </p>
//             </div>
//           ) : (
//             <p>Loading...</p>
//           )}
//         </Modal.Body>
//         <Modal.Footer>
//           <button className="btn btn-secondary" onClick={closeModal}>
//             Close
//           </button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// }
