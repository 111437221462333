import React, { useEffect, useState } from "react";
import {
  getAllProduct,
  editProduct,
  deleteProduct,
  deleteVariantProduct,
} from "../../services/APIService"; // Adjust path as necessary
import Loader from "../../services/Loader"; // Ensure Loader component is imported

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductDeatil, setSelectedProductDeatil] = useState(null);  // State for selected product
  const [selectDetailsModal, setShowDetailsModal] = useState(null);

  const [formData, setFormData] = useState({
    productName: "",
    description: "",
    productDescription: "",
    brand: "",
    prodId: "",
    catId: "",
    subCatId: "",
    slug: "",
    categoryName: "",
    subCategoryName: "",
    discount: "",
    price: "",
    availableQty: "",
    variantId: "",
    vendorId:"",
  });
  const [showModal, setShowModal] = useState(false); // State to toggle modal visibility
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");

  console.log("successMessage", successMessage);
  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getAllProduct();
        // Sort products by `updatedAt` in descending order
        const sortedProducts = data.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setProducts(sortedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Pagination Logic
  const totalPages = Math.ceil(products.length / rowsPerPage);
  const displayedProducts = products.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page
  };
  // Handlers for the action icons
  const viewProductDetails = (product) => {
    console.log("Viewing product:", product);
    setSelectedProductDeatil(product);
    setShowDetailsModal(true);
    // Implement view logic here
  };

  const closeDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedProductDeatil(null);
  };

  const editProductDetails = (product) => {
    console.log("Editing product:", product);
    const { vendorVariants = [] } = product;
    const variant = vendorVariants[0] || {}; // Assuming one variant for simplicity
    setSelectedProduct(product);
    setFormData({
      productName: product.productName,
      prodId: product.prodId,
      catId: product.catId,
      subCatId: product.subCatId,
      description: product.description,
      productDescription: product.productDescription,
      slug: product.slug,
      categoryName: product.categoryName,
      subCategoryName: product.subCategoryName,
      brand: product.brand,
      price: variant.price || "",
      discount: variant.discount || "",
      idVendorVariant: variant.idVendorVariant || "",
      // Initialize other fields as needed
    });
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedProduct = await editProduct(formData);
      setShowMsg(true);
      setMsgClass("alert alert-success");
      setMsgText("Product updated successfully!");
      setSuccessMessage("Product updated successfully!"); // Set success message
      console.log("Product updated:", updatedProduct);
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.prodId === updatedProduct.prodId ? updatedProduct : product
        )
      );
      setShowModal(false); // Close the modal
      setTimeout(() => setSuccessMessage(""), 3000); // Hide the message after 3 seconds
    } catch (error) {
      console.error("Error updating product:", error);
      setSuccessMessage(""); // Reset the message on error
      setMsgText("");
    }
  };

  const handleDeleteProduct = async (prodId, variant) => {
    console.log("variant", variant);
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        // Step 1: Delete each variant associated with the product
        const variantPayload = {
          variantId: variant.variantId,
          productId: prodId,
          variation: variant.variation,
          crud: variant.crud,
          images: variant.images,
          imageId: variant.imageId,
          entityId: variant.entityId,
          entityType: variant.entityType,
          imageUrl: variant.image,
          price: variant.price,
          discount: variant.discount,
          availableQty: variant.availableQty,
          variantId: variant.variantId,
          vendorId: variant.vendorId,
          vendorVariants: variant.vendorVariants,
        };

        console.log("Deleting variant:", variantPayload);
        await deleteVariantProduct(variantPayload);

        // Step 2: Delete the product after deleting all variants
        const productPayload = {
          prodId: prodId,
        };
        console.log("Deleting product:", productPayload);
        await deleteProduct(productPayload);

        // Update the product list in the state
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.prodId !== prodId)
        );

        // Display success message
        setShowMsg(true);
        setMsgText("Product and its variants deleted successfully!");
        setMsgClass("alert alert-success");

        // Hide the message after 3 seconds
        setTimeout(() => setShowMsg(false), 3000);
      } catch (error) {
        console.error("Error deleting product or its variants:", error);

        // Display error message
        setShowMsg(true);
        setMsgClass("alert alert-danger");
        setMsgText("Failed to delete the product or its variants.");

        // Hide the message after 3 seconds
        setTimeout(() => setShowMsg(false), 3000);
      }
    }
  };

  return (
    <div className="dashboard-influence">
      {loading && <Loader />}
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="page-header">
              <h3 className="mb-2">Product List</h3>
            </div>
          </div>
        </div>
        {showMsg && (
          <div className={msgClass} role="alert">
            <strong>{msgText}</strong>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
        )}

        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                {products.length > 0 ? (
                  <>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Product ID</th>
                          <th scope="col">Product Name</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayedProducts.map((product) => (
                          <tr key={product.prodId}>
                            <td>{product.prodId}</td>
                            <td>{product.productName}</td>
                            <td>
                              <div>
                                <i
                                  onClick={() => viewProductDetails(product)}
                                  className="fas fa-eye mr-3 text-info fa-lg"
                                  style={{ cursor: "pointer" }}
                                ></i>
                                <i
                                  onClick={() => editProductDetails(product)}
                                  className="fas fa-edit mr-3 text-primary fa-lg"
                                  style={{ cursor: "pointer" }}
                                ></i>
                                <i
                                  onClick={() =>
                                    handleDeleteProduct(
                                      product.prodId,
                                      product?.variants[0]
                                    )
                                  }
                                  className="fas fa-trash-alt text-danger fa-lg"
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/* Pagination Controls */}
                    <div className="d-flex justify-content-end align-items-center">
                      <div>
                        Rows per page:{" "}
                        <select
                          value={rowsPerPage}
                          onChange={handleRowsPerPageChange}
                        >
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={15}>15</option>
                        </select>
                      </div>
                      <div>
                        {`${(currentPage - 1) * rowsPerPage + 1}-${Math.min(
                          currentPage * rowsPerPage,
                          products.length
                        )} of ${products.length}`}
                      </div>
                      <div>
                        <button
                          className="btn btn-link"
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(1)}
                        >
                          {"|<"}
                        </button>
                        <button
                          className="btn btn-link"
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          {"<"}
                        </button>
                        <button
                          className="btn btn-link"
                          disabled={currentPage === totalPages}
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          {">"}
                        </button>
                        <button
                          className="btn btn-link"
                          disabled={currentPage === totalPages}
                          onClick={() => handlePageChange(totalPages)}
                        >
                          {">|"}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  !loading && <p>No products available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for editing product */}
      {showModal && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Product</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="productName">Product Name</label>
                    <input
                      type="text"
                      id="productName"
                      name="productName"
                      className="form-control"
                      value={formData.productName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">Product Description</label>
                    <textarea
                      id="description"
                      name="description"
                      className="form-control"
                      value={formData.description}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="slug">Slug</label>
                    <input
                      type="text"
                      id="slug"
                      name="slug"
                      className="form-control"
                      value={formData.slug}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="availableQty">Available Qty</label>
                    <input
                      type="number"
                      id="availableQty"
                      name="availableQty"
                      className="form-control"
                      value={formData.availableQty}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="price">Price</label>
                    <input
                      type="number"
                      id="price"
                      name="price"
                      className="form-control"
                      value={formData.price}
                      onChange={handleInputChange}
                    />
                  </div>

                  {/* Add more fields as needed */}
                  <button className="btn btn-primary" onClick={handleSubmit}>
                    Save Changes
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal for viewing product details */}
      {setShowDetailsModal && selectedProductDeatil && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Product Details</h5>
                <button
                  type="button"
                  className="close"
                  onClick={closeDetailsModal}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <strong>Product Name:</strong>{" "}
                  {selectedProductDeatil.productName}
                </p>
                <p>
                  <strong>Category Name:</strong>{" "}
                  {selectedProductDeatil.categoryName}
                </p>
                <p>
                  <strong>Description:</strong>{" "}
                  {selectedProductDeatil.description}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  onClick={closeDetailsModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductList;
